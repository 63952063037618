import axios from "axios"
import { graphql, StaticQuery } from "gatsby"
import mixpanel from 'mixpanel-browser'
import Slider from "rc-slider"
import React, { useEffect, useRef, useState } from "react"
import AnimatedNumber from "react-animated-number/build/AnimatedNumber"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import Buttons from "../../components/common/button"
import CTA from "../../components/common/CTA"
import handleFormSubmit from "../../components/common/FormBuilder/Form-Function"
import Frame from "../../components/common/frame"
import SVGIcon from "../../components/common/SVGIcon"
import { preventInitialZero } from "../../components/common/util-functions"
import Navigation from "../../components/navigation"
import { checkValidEmail } from "../../components/utility/validation"
import "../../styles/pages/roi.scss"
const ROI = () => {
  const [totalEndpoints, setTotalEndpoints] = useState(0)
  const [totalTechnicians, setTotalTechnicians] = useState(1)
  const [selectedOption, setSelectedOption] = useState("PSA + RMM")
  const [productName, setProductName] = useState("")
  const [emailId, setEmailId] = useState("")
  const [currentPlanAmt, setCurrentPlanAmt] = useState(0)
  const [spsPlanAmt, setSpsPlanAmt] = useState(0)
  const [showTooltip, setShowTooltip] = useState(false)
  const [showAmtDiff, setShowAmtDiff] = useState(true)
  const [currencyType, setCurrencyType] = useState({
    name: "USD",
    symbol: "$",
    epCost: 65,
  })
  const [lossAmt, setLossAmt] = useState(0)
  const currencies = [
    { name: "USD", symbol: "$", epCost: 65 },
    { name: "AUD", symbol: "$", epCost: 95 },
    { name: "GBP", symbol: "£", epCost: 55 },
    { name: "EURO", symbol: "€", epCost: 65 },
  ]
  const [countryPopup, setCountryPopup] = useState(false)
  const wrapperRef = useRef(null)

  const [IpData, setIpData] = useState({
    continent: "No Continent",
    continentcode: "No Continentcode",
    countrycode: "No Countrycode",
    country: "No Country",
    state: "No State",
    city: "No City",
    zip: "No Zip",
    countryphoneCode: 0,
  })

  function CheckIpdata(resdata, alt) {
    const data = resdata != null && resdata != "" ? resdata : alt
    return data
  }

  useEffect(() => {
    axios.get(process.env.IP_STACK_API).then(function(response) {
      setIpData({
        continent: CheckIpdata(response.data.continent_name, IpData.continent),
        continentcode: CheckIpdata(
          response.data.continent_code,
          IpData.continentcode
        ),
        countrycode: CheckIpdata(
          response.data.country_code,
          IpData.countrycode
        ),
        country: CheckIpdata(response.data.country_name, IpData.country),
        state: CheckIpdata(response.data.region_name, IpData.state),
        city: CheckIpdata(response.data.city, IpData.city),
        zip: CheckIpdata(response.data.zip, IpData.zip),
        countryphoneCode: CheckIpdata(
          response.data.location.calling_code,
          IpData.countryphoneCode
        ),
      })
    })
  }, [])

  const IpStackData = [
    {
      name: "continent",
      value: IpData.continent,
    },
    {
      name: "continent_code",
      value: IpData.continentcode,
    },
    {
      name: "country_code",
      value: IpData.countrycode,
    },
    {
      name: "country",
      value: IpData.country,
    },
    {
      name: "state",
      value: IpData.state,
    },
    {
      name: "city",
      value: IpData.city,
    },
    {
      name: "zip",
      value: IpData.zip,
    },
    {
      name: "country_phone_code",
      value: IpData.countryphoneCode,
    },
  ]

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setCountryPopup(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(()=>{
    mixpanel.init(`ca27d5fec490a19f8cac8ee64cd06906`,{debug: true, track_pageview: true, persistence: 'localStorage'})
  },[])

  const handleRadioChange = event => {
    setSelectedOption(event.target.value)
  }
  const checkEPRange = value => {
    return value >= 0 && value <= 10000
  }
  const checkTechRange = value => {
    return value >= 0 && value <= 50
  }

  const calculatePlanAmt = () => {
    document
      .getElementById(`result_box_id`)
      .scrollIntoView({ behavior: "smooth" })
    let planAmount = 0
    if (selectedOption === "PSA only") {
      planAmount = 59 * totalTechnicians * 12
      if (currentPlanAmt) {
        setShowTooltip((planAmount / 59) * 99 < currentPlanAmt)
        let difference = currentPlanAmt - planAmount
        setShowAmtDiff(difference > 0)
        setLossAmt(difference >= 0 ? difference : difference * -1)
      }
      setSpsPlanAmt(planAmount)
    } else if (selectedOption === "RMM only") {
      planAmount =
        79 * totalTechnicians * 12 +
        (totalEndpoints > totalTechnicians * 150
          ? ((totalEndpoints - totalTechnicians * 150) / 150) *
            currencyType.epCost *
            12
          : 0)
      if (currentPlanAmt) {
        setShowTooltip((planAmount / 79) * 99 < currentPlanAmt)
        let difference = currentPlanAmt - planAmount
        setShowAmtDiff(difference > 0)
        setLossAmt(difference >= 0 ? difference : difference * -1)
      }
      setSpsPlanAmt(planAmount)
    } else {
      planAmount =
        99 * totalTechnicians * 12 +
        (totalEndpoints > totalTechnicians * 150
          ? ((totalEndpoints - totalTechnicians * 150) / 150) *
            currencyType.epCost *
            12
          : 0)
      if (currentPlanAmt) {
        let difference = currentPlanAmt - planAmount
        setShowAmtDiff(difference > 0)
        setLossAmt(difference >= 0 ? difference : difference * -1)
      }
      setSpsPlanAmt(planAmount)
      setShowTooltip(false)
    }

    if (emailId.length > 0 && checkValidEmail(emailId)) {
      let final_data = [
        {
          name: "email",
          value: emailId,
        },
        {
          name: "number_of_endpoints",
          value: totalEndpoints,
        },
        {
          name: "number_of_technicians",
          value: totalTechnicians,
        },
        {
          name: "cost_of_current_tech_stack",
          value: currentPlanAmt,
        },
        {
          name: "current_tool_set",
          value: productName,
        },
      ]
      final_data = [...final_data, ...IpStackData]
      handleFormSubmit(
        final_data,
        process.env.HUBSPOT_SAVINGS_CALCULATOR,
        process.env.HUBSPOT_SAVINGS_CALCULATOR_API_URL,
        `Savings calculator - Superops`
      )
    } else {
      mixpanel.track("ROI_CALCULATOR",[
        {
          name: "number_of_endpoints",
          value: totalEndpoints,
        },
        {
          name: "number_of_technicians",
          value: totalTechnicians,
        },
        {
          name: "cost_of_current_tech_stack",
          value: currentPlanAmt,
        },
        {
          name: "current_tool_set",
          value: productName,
        },
      ])
    }
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query roi {
            SuperOps {
              pages(where: { title: "ROI calculator" }) {
                title
                seo {
                  title
                  description
                  image {
                    url
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    tag
                    backgroundColor
                    heroHeading: heading {
                      text
                      html
                    }
                  }
                  ... on SuperOps_Card {
                    cardName
                    heading
                    title
                  }
                  ... on SuperOps_CtaFeature {
                    ctaBox {
                      type
                      theme
                      customBackgroundColor
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                      secondaryButtonText
                      secondaryButtonLink
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField } = page
              const hero = newField[0]
              const CalcBox = newField[1]
              const ResultBox = newField[2]
              const Advantages = newField[3]
              const CTABox = newField[4]
              let navBgColor = hero.backgroundColor
              navBgColor = navBgColor.slice(
                navBgColor.indexOf("#"),
                navBgColor.indexOf("#") + 7
              )

              return (
                <div className="roi-calc">
                  <Frame seo={seo} newButton>
                    <header>
                      <Navigation
                        color={navBgColor}
                        page="About us"
                        dskScroll={450}
                        newButton
                      />
                    </header>
                    <section
                      className="hero pt80"
                      style={{ background: `${hero.backgroundColor}` }}
                    >
                      <Container className="Layout-container text-center">
                        <p className="let-space-4 text-pink down-to-up-1">
                          {hero.tag}
                        </p>
                        <h1 className="heading font-roboto fw-800 mx-auto mt24 down-to-up-2">
                          {hero.heroHeading.text}
                        </h1>
                      </Container>
                    </section>

                    <section className="calc-result down-to-up-3">
                      <Container className="Layout-container">
                        <Row className="justify-content-between gap-24">
                          <Col lg={7} className="calc-box main-box br-10 forms">
                            <p className="font-roboto fw-600">
                              {CalcBox.heading[1]}
                            </p>
                            <div className="d-flex align-items-center slider-wrapper">
                              <Slider
                                range
                                value={totalTechnicians}
                                min={0}
                                max={50}
                                marks={{
                                  0: <strong>0</strong>,
                                  50: <strong>50</strong>,
                                }}
                                onChange={val => {
                                  setTotalTechnicians(parseInt(val))
                                  // console.log("total tech", val)
                                }}
                              />
                              <input
                                type="number"
                                value={totalTechnicians}
                                min={0}
                                max={50}
                                className="slide-val-box fw-bold d-flex justify-content-center text-center m-0"
                                onChange={e => {
                                  const tempValue = e.target.value
                                  checkTechRange(tempValue) &&
                                    setTotalTechnicians(
                                      tempValue
                                        ? preventInitialZero(tempValue)
                                        : 0
                                    )
                                }}
                              />
                            </div>
                            <p
                              className={`font-roboto fw-600 mt56 ${
                                selectedOption === "PSA only" ? "disabled" : ""
                              }`}
                            >
                              {CalcBox.heading[0]}
                            </p>
                            <div
                              className={`d-flex align-items-center slider-wrapper ${
                                selectedOption === "PSA only" ? "disabled" : ""
                              }`}
                            >
                              <Slider
                                range
                                value={totalEndpoints}
                                min={0}
                                max={10000}
                                marks={{
                                  0: <strong>0</strong>,
                                  10000: <strong>10000</strong>,
                                }}
                                onChange={val => setTotalEndpoints(val)}
                              />
                              <input
                                type="number"
                                value={totalEndpoints}
                                min="0"
                                max="10000"
                                className="slide-val-box fw-bold d-flex justify-content-center text-center m-0"
                                onChange={e => {
                                  const tempValue = e.target.value
                                  checkEPRange(tempValue) &&
                                    setTotalEndpoints(
                                      tempValue
                                        ? preventInitialZero(tempValue)
                                        : 0
                                    )
                                }}
                              />
                            </div>
                            <p className="font-roboto fw-600 mt56 mb24">
                              {CalcBox.heading[2]}
                            </p>
                            <div className="d-flex flex-wrap gap-24">
                              {CalcBox.title.map(item => {
                                return (
                                  <div className="d-flex align-items-center gap-8">
                                    <input
                                      type="radio"
                                      id={item}
                                      value={item}
                                      checked={selectedOption === item}
                                      onChange={handleRadioChange}
                                    />
                                    <label for={item} className="label fw-500">
                                      {item}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                            <div className="position-relative mt56">
                              <input
                                type="text"
                                value={productName}
                                onChange={e => setProductName(e.target.value)}
                                className={`w-100 ${
                                  productName.length > 0 ? "valid" : ""
                                }`}
                              />
                              <span className="floating-label p14 position-absolute">
                                <p className="font-roboto">
                                  {CalcBox.heading[3]}
                                </p>
                              </span>
                            </div>
                            <div className="head-curr-price d-flex mt16">
                              <p className="font-roboto fw-600 m-0">
                                {CalcBox.heading[4]}
                              </p>
                              <div className="d-flex align-items-center curr-price">
                                <div
                                  className="currency d-flex align-items-center"
                                  ref={wrapperRef}
                                  onClick={() => setCountryPopup(!countryPopup)}
                                >
                                  <p className="m-0 position-relative fw-500">
                                    {currencyType.name}
                                    <div
                                      className={`position-absolute pop-up-location br-10 z-1 ${
                                        countryPopup ? "" : "dspnone"
                                      }`}
                                    >
                                      {currencies.map(item => {
                                        return (
                                          <p
                                            className={`text fw-bold p14 ${
                                              item.name === currencyType.name
                                                ? "text-pink"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              setCurrencyType(item)
                                            }
                                          >
                                            {item.name}
                                          </p>
                                        )
                                      })}
                                    </div>
                                  </p>
                                  <SVGIcon
                                    name="hypertext-arrow-right"
                                    className={`arrow-right ${
                                      countryPopup ? "active" : ""
                                    }`}
                                  />
                                </div>
                                <input
                                  type="number"
                                  value={currentPlanAmt}
                                  min="0"
                                  className="price fw-bold d-flex justify-content-center text-center m-0 h-100"
                                  onChange={e => {
                                    const tempValue = e.target.value
                                    setCurrentPlanAmt(
                                      tempValue
                                        ? preventInitialZero(tempValue)
                                        : 0
                                    )
                                  }}
                                />
                              </div>
                            </div>
                            <div className="position-relative mt56">
                              <input
                                type="text"
                                value={emailId}
                                onChange={e => setEmailId(e.target.value)}
                                className={`w-100 m-0 ${
                                  emailId.length > 0 ? "valid" : ""
                                }`}
                              />
                              <span className="floating-label p14 position-absolute">
                                <p className="font-roboto">
                                  {CalcBox.heading[5]}
                                </p>
                              </span>
                            </div>
                            <Buttons
                              theme="secondary-new fw-600 w-100 mt32"
                              text={CalcBox.heading[6]}
                              onClick={() => calculatePlanAmt()}
                            />
                          </Col>
                          <Col
                            lg={5}
                            className="result-box main-box br-10 d-flex flex-column justify-content-center"
                            id="result_box_id"
                          >
                            <p className="tag text-pink let-space-4 mb30">
                              {ResultBox.cardName}
                            </p>

                            <div className="first mb16 br10">
                              <p className="p24 font-roboto fw-600 mb24">
                                {ResultBox.heading[0]}
                              </p>
                              <div className="d-flex align-center gap-8">
                                <div className="d-flex flex-column justify-content-center">
                                  <p className="p18 m-0">
                                    {currencyType.symbol}
                                  </p>
                                  <p className="p12 m-0 usd">
                                    {currencyType.name}
                                  </p>
                                </div>
                                <h1 className="fw-bold number m-0">
                                  <AnimatedNumber
                                    component="k"
                                    value={parseInt(spsPlanAmt)}
                                    duration={300}
                                    stepPrecision={0}
                                  />
                                </h1>
                              </div>
                            </div>

                            <div className="second altv3 br10">
                              {showAmtDiff ? (
                                <>
                                  <h1 className="font-roboto fw-900 text-1">
                                    {ResultBox.heading[1]}
                                  </h1>
                                  <div className="d-flex align-center gap-8">
                                    <div className="d-flex flex-column align-center">
                                      <h2 className="p18 m-0">
                                        {currencyType.symbol}
                                      </h2>
                                      <p className="p14 m-0 usd">
                                        {currencyType.name}
                                      </p>
                                    </div>
                                    <h1 className="fw-bold text-pink number m-0">
                                      <AnimatedNumber
                                        component="k"
                                        value={parseInt(lossAmt)}
                                        duration={300}
                                        stepPrecision={0}
                                      />
                                    </h1>
                                  </div>
                                  <div className="d-flex align-items-center gap-8">
                                    <h1 className="font-roboto fw-900">
                                      {ResultBox.heading[2]}
                                    </h1>
                                    {showTooltip && (
                                      <div className="position-relative">
                                        <SVGIcon name="infoIcon" />
                                        <p className="info-tooltip fw-500 position-absolute m-0">
                                          {ResultBox.heading[6]}{" "}
                                          {selectedOption === "PSA only"
                                            ? "PSA"
                                            : "RMM"}
                                          {"!"}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <h4 className="font-roboto fw-800 mb24">
                                    {ResultBox.heading[3]}
                                  </h4>
                                  <p className="p18 fw-500 m-0">
                                    {ResultBox.heading[4]}
                                  </p>
                                </>
                              )}
                            </div>
                            <Buttons
                              theme="primary-new fw-600 w-100 mt40"
                              text={ResultBox.heading[7]}
                              link="/signup"
                            />
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="advantage mt80">
                      <Container className="Layout-container">
                        <Col lg={8} className="mx-auto">
                          <div className="altv3">
                            <Fade bottom distance="20px">
                              <h1 className="font-roboto text-center m-0 fw-bold">
                                {Advantages.cardName}
                              </h1>
                            </Fade>
                          </div>
                          <Fade bottom distance="20px">
                            <Row className="d-flex flex-wrap mt40">
                              {Advantages.title.map(item => {
                                return (
                                  <Col lg={6} className="d-flex gap-8">
                                    <div>
                                      <SVGIcon
                                        name="greenTick"
                                        className="tick-logo"
                                      />
                                    </div>

                                    <p className="p16 mb30 text fw-600">
                                      {item}
                                    </p>
                                  </Col>
                                )
                              })}
                            </Row>
                          </Fade>
                        </Col>
                      </Container>
                    </section>

                    <section className="mt80">
                      <Fade bottom distance="20px">
                        <CTA
                          data={[newField[4].ctaBox]}
                          className="Layout-container"
                          maxDescriptionWidth="500px"
                          lgLeft={7}
                          lgRight={4}
                          rightStyles={{ alignItems: "center" }}
                          newButton
                          newDesign
                        />
                      </Fade>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default ROI
